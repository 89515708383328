.credential {
    font-family: 'Montserrat', sans-serif;
}

.outside {
    display: flex;
    flex-wrap: wrap;
    border-radius: 1.5em;
    border-color: green;
    border-style: solid;
    border-width: thin;
    height: 280px;
    width: 500px;
}

.club-section {
    background-color: seagreen;
    border-top-left-radius: 1.3em;
    border-top-right-radius: 1.3em;
    height: 25%;
    width: 100%;
}

.club-section-first-line {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.club-section-second-line {
    display: block;
    margin-top: -2.0em;
    text-align: right;
    width: 71%;
}

.club-font {
    color: white;
    font-size: 22px;
    letter-spacing: 0.075em;
    margin: 0;
    padding-top: 1em;
    width: inherit;
}

.member-number-font {
    color: white;
}

.club-logo {
    margin-left: 1.5em;
}

.main-section {
    display: flex;
    height: 62%;
    margin-top: 0.5em;
    width: 100%;
}

.picture-section {
    display: inline-block;
    padding-left: 1.5em;
    width: 30%;
}

.data-section {
    display: inline-block;
    width: 45%;
}

.qr-section {
    text-align: center;
}

.data-section-below-picture {
    display: block;
    margin-top: -2.6em;
    margin-left: 1.5em;
    width: 35%;
}

.data-field-member-picture {
    margin-top: 0.85em;
}

.data-field-value {
    display: block;
}

.data-field {
    width: 100%;
}

.label {
    color: darkgreen;
    margin-bottom: 0em;
    font-size: 12px;
}

.value {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
}

.club-section-bottom {
    background-color: seagreen;
    border-bottom-left-radius: 1.3em;
    border-bottom-right-radius: 1.3em;
    height: 10%;
    width: 100%;
}

.club-font-slogan {
    color: white;
    align-content: center;
    font-family: sloganFont, serif;
    font-size: 22px;
    letter-spacing: 0.05em;
    margin: 0;
    width: inherit;
}

.club-section-bottom-line {
    display: flex;
    justify-content: center;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.helper {
    display: inline-block;
    /*height: 100%;*/
    vertical-align: middle;
}

#qr {
    max-height: 120px;
    max-width: 120px;
    vertical-align: middle;
}

#credential-member-picture {
    margin-top: -1.5em;
}

@media screen and (max-width: 575px) {

    .outside {
        display: flex;
        flex-wrap: wrap;
        border-radius: 1.5em;
        border-color: green;
        border-style: solid;
        border-width: thin;
        height: max-content;
        width: 500px;
    }

    .main-section {
        flex-wrap: wrap;
    }

    .picture-section {
        margin-right: 0;
        margin-top: 1.25em;
        width: 50%;
    }

    .data-section {
        width: 50%;
    }

    .qr-section {
        justify-content: center;
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;
    }

    .club-section {
        height: 20%;
    }

    .club-font {
        font-size: 18px;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .club-logo {
        margin-left: 0;
        width: 80px;
    }

    #credential-member-picture {
        margin-top: 0;
    }

    #qr {
        display: inline-block;
    }
}
